import React from 'react';
import { BrowserRouter as Router,  Route, Routes } from 'react-router-dom';
import { Box, Grommet } from 'grommet';

import Home from './pages/Home';
import Links from './pages/Links';
import About from './pages/About';
import Private from './pages/Private';
import GroupPrivate from './pages/GroupPrivate';
import Accounts from './pages/Accounts';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Navigation from './components/Navigation';
import Error from './components/Error';

const globalTheme = {
  global: {
    font: {
      family: `-apple-system,
         BlinkMacSystemFont,
         "Segoe UI"`,
    },
    colors: {
      blue: '#00C8FF',
      green: '#17EBA0',
      teal: '#82FFF2',
      purple: '#F740FF',
      red: '#FC6161',
      orange: '#FFBC44',
      yellow: '#FFEB59',
    },
  },
  card: {
    footer: {
      pad: { horizontal: 'medium', vertical: 'small' },
      background: '#FFFFFF27',
    },
  },
};


function App() {


  return (
    <Grommet theme={globalTheme}>
      
      <Router>
        <Box alignSelf="center" align="center" >
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/links" element={<Links />} />
            <Route path="/about" element={<About />} />
            <Route path="/private" element={<Private />} />
            <Route path="/group" element={<GroupPrivate />} />
            <Route path="/accounts" element={<Accounts />} />
            <Route path="/login" element={<Login />} />
            <Route path="/logout" element={<Logout />} />
            <Route component={Error} />
          </Routes>
        </Box>
      </Router>
    </Grommet>
  );
}

export default App;
