import React from 'react';
import { Box, Anchor, Heading, Sidebar} from 'grommet';
 
const Links = () => {
    return (
      <Box
         width="medium"
         background="light-3"
         align="left"
         margin="small"
         pad="small"
      >
        <Heading margin={{ top: 'none' }}>Links</Heading>
         
        <Sidebar title="Investing">
            <Anchor href="https://www.cnbc.com/" >CNBC</Anchor>
            <Anchor href="https://finchat.io/" >FinChat</Anchor>
            <Anchor href="https://ca.finance.yahoo.com/" >Yahoo Finance</Anchor>
            <Anchor href="https://www.google.com/finance/" >Google Finance</Anchor>
            <Anchor href="https://dividendstrategy.ca/" >Dividend Strategy</Anchor>
            <Anchor href="https://cutthecrapinvesting.com/" >Cut the Crap Investing</Anchor>
        </Sidebar>
        <Sidebar title="React">
            <Anchor href="https://auth0.com/" >Auth0</Anchor>
            <Anchor href="https://reactjs.org/" >React</Anchor>
        </Sidebar>

         </Box>
         );
     }
      
     export default Links;